<template>
  <div class="mt-n5">
    <v-row>
      <v-col  cols="1" style="max-width: 30px">
        <defaultFieldTypes
          :fieldAttributes="fieldAttributes"
          :field="field"
          :templateContent="result"
          v-on="$listeners"
          :value="value"
        ></defaultFieldTypes>
      </v-col>
      <v-col style="position: relative;top:10px">
        I have read, understand and accept the
        <a href="https://www.acctopus.com/legal/" target="_blank"
          >Terms & Conditions</a
        >,
        <a href="https://www.acctopus.com/legal/degust_privacy" target="_blank"
          >Privacy Policy</a
        > and
        <a href="https://www.acctopus.com/legal/degust_cookies" target="_blank"
          >Cookies Policy</a
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result", "value"],
};
</script>